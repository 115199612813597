<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
    class="my-container-style"
  >
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <p
        class="message-style"
      >
        Current ticker setting of your organization
      </p>
      <br>
      <div class="organization-ticker-settings-alert">
        <h4 v-if="!isOrganizationTickerSettingExist">
          No ticker setting found against your organization.
        </h4>
      </div>
      <v-container
        fluid
      >
        <v-row v-if="isOrganizationTickerSettingExist">
          <v-col
            cols="12"
            md="3"
            class="my-col-style"
          >
            <p class="my-p-style">
              Name:-
              <font
                v-if="organizationTickerSetting.name !== null"
                class="font-my-style"
              >
                {{ organizationTickerSetting.name | firstLetterCapital }}
              </font>
              <font
                v-else
                class="font-my-style"
              >
                N/A
              </font>
            </p>
            <p class="my-p-style">
              Ticker Position:-
              <font class="font-my-style">
                {{ organizationTickerSetting.position | firstLetterCapital }}
              </font>
            </p>
            <p class="my-p-style">
              Title Position:-
              <font class="font-my-style">
                {{ organizationTickerSetting.title | firstLetterCapital }}
              </font>
            </p>
            <p class="my-p-style">
              Bg Color:-
              <v-icon
                size="30"
                :color="organizationTickerSetting.bg_color"
              >
                mdi-rectangle
              </v-icon>
            </p>
            <p class="my-p-style">
              Direction:-
              <font class="font-my-style">
                {{ organizationTickerSetting.direction | firstLetterCapital }}
              </font>
            </p>
            <p class="my-p-style">
              Speed:-
              <font class="font-my-style">
                {{ organizationTickerSetting.speed }}s
              </font>
            </p>
            <p class="my-p-style">
              Text Color:-
              <v-icon
                size="30"
                :color="organizationTickerSetting.text_color"
              >
                mdi-rectangle
              </v-icon>
            </p>
            <div class="py-3" />
          </v-col>
          <v-col
            cols="12"
            md="9"
          >
            <v-container>
              <div class="board-div">
                <v-img
                  :src="logo"
                  width="130"
                  height="110"
                  class="blink-image"
                />
                <div>
                  <div
                    id="update-div-style"
                    class="updates"
                  >
                    <span><b>{{ currentDateTime }}</b></span>
                  </div>
                  <div
                    id="ticker-div-style"
                    class="ticker-div"
                  >
                    <p :class="tickerScrollSettings">
                      <span v-if="isTickerTitleBefore"><b class="ticker-title-before">Title:</b><b class="ticker-comment-after">This is a sample scrolling text</b></span>
                      <span v-else><b class="ticker-comment-before">This is a sample scrolling text</b><b class="ticker-title-after">-Title</b></span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="stand-div" />
            </v-container>
            <div class="py-3" />
          </v-col>
        </v-row>
      </v-container>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </v-container>
</template>
<script>
 import spinner from 'src/views/dashboard/component/SpinnerCentre';
 import { cdnUrl } from 'src/app-globals';
 import moment from 'moment';

 export default {
   name: 'OrganizationTickerSetting',
   components: {
     'centre-spinner': spinner,
   },
    filters: {
      firstLetterCapital (val) {
        if (val !== undefined) {
          return val[0].toUpperCase() + val.slice(1);
        }
      },
    },
  data () {
     return {
      loading: false,
      logo: `${cdnUrl}/website/DBS-Logo.png`,
      currentDateTime: null,
      interval: null,
    };
  },
  computed: {
    organizationTickerSetting () {
      return this.$store.getters['widgets/getOrganizationTickerSetting'];
     },
    tickerScrollSettings () {
      if (this.organizationTickerSetting.direction === 'left') {
        return 'ticker-content-to-left';
      }
      return 'ticker-content-to-right';
    },
    isTickerTitleBefore () {
      if (this.organizationTickerSetting.title === 'before') {
        return true;
      }
      return false;
    },
    isOrganizationTickerSettingExist () {
      if (Object.keys(this.organizationTickerSetting).length > 0) {
        return true;
      }
        return false;
    },
  },
   watch: {
      organizationTickerSetting (newValue, oldValue) {
        if (Object.keys(oldValue).length > 0) {
          this.applyStylings();
        }
      },
    },
    created () {
      this.currentDateTime = moment().format('MMM DD YYYY, ddd');
      setInterval(() => {
        clearInterval(this.interval);
        this.liveDateTime();
      }, 6000);
    },
   async mounted () {
     await this.fetchOrganizationTickerSetting();
     await this.applyStylings();
   },
   methods: {
     async fetchOrganizationTickerSetting () {
       this.loading = true;
       await this.$store.dispatch('widgets/fetchOrganizationTickerSetting');
       this.loading = false;
     },
     applyStylings () {
      if (this.isOrganizationTickerSettingExist === false) {
        return;
      }
       document.getElementById('ticker-div-style').style.color = this.organizationTickerSetting.text_color;
       document.getElementById('ticker-div-style').style.background = this.organizationTickerSetting.bg_color;
       document.documentElement.style.setProperty(
          '--my-ticker-speed',
          `${this.organizationTickerSetting.speed}s`,
        );
       if (this.organizationTickerSetting.position === 'top') {
        document.getElementById('ticker-div-style').style.top = 0;
        document.getElementById('update-div-style').style.top = 0;
        document.getElementById('ticker-div-style').style.bottom = 'unset';
        document.getElementById('update-div-style').style.bottom = 'unset';
        } else {
          document.getElementById('ticker-div-style').style.bottom = 0;
          document.getElementById('update-div-style').style.bottom = 0;
          document.getElementById('ticker-div-style').style.top = 'unset';
          document.getElementById('update-div-style').style.top = 'unset';
        }
     },
     liveDateTime () {
        const todaysDate = moment().format('MMM DD YYYY, ddd');
        if (this.currentDateTime === todaysDate) {
          this.interval = setInterval(() =>
            this.getCurrentTime(),
          );
        } else {
          this.currentDateTime = todaysDate;
        }
      },
      getCurrentTime () {
        this.currentDateTime = moment().format('LTS');
      },
   },
 };
</script>
<style scoped>
.my-container-style {
 padding: 0px;
}
.font-my-style {
 font-weight: bold;
 font-size: 15px;
 color: #37474F;
}
.my-p-style {
 font-size: 16px;
 font-weight: bold;
 color: #546E7A;
 font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.board-div {
 height: 300px;
 max-width: 600px;
 overflow: hidden;
 position: relative;
 background-color: black;
 margin: auto;
}
@media (max-width: 767px) {
 .board-div {
   width: 100%;
   height: 200px;
 }
}
.stand-div {
 height: 60px;
 width: 10px;
 overflow: hidden;
 position: relative;
 background-color: black;
 margin: auto;
}
.blink-image {
 position: absolute;
 margin: auto;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 animation: blink 1s step-start infinite;
}
@keyframes blink {
 50% {
   opacity: 0;
 }
}
.updates {
  font-size: 13px;
  font-weight: bold;
  padding: 6px;
  background: white;
  color: black;
  font-family: 'Times New Roman', Times, serif;
  position: absolute;
  z-index: 999;
}
@media (max-width: 767px) {
 .updates {
   font-size: 10px;
 }
}
.ticker-div {
  position: absolute;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: visible;
  padding-top: 4px;
  padding-bottom: 4px;
  z-index: 998;
}
.ticker-content-to-left {
  margin: 0;
  display: inline-block;
  overflow: hidden;
  font-size: 15px;
  padding-left: 100%;
  animation: tickerTape var(--my-ticker-speed) linear infinite;
}
@keyframes tickerTape {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
.ticker-content-to-right {
  margin: 0;
  display: inline-block;
  overflow: hidden;
  font-size: 15px;
  padding-left: 100%;
  animation: tickerTapes var(--my-ticker-speed) linear infinite;
}
@keyframes tickerTapes {
  0% {
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.ticker-title-before {
  margin-right: 5px;
  text-decoration: underline;
}
.ticker-comment-after {
  margin-right: 30px;
}
.ticker-title-after {
  margin-left: 5px;
  text-decoration: underline;
}
.ticker-comment-before {
  margin-left: 30px;
}
.message-style {
  font-size: 20px;
  font-weight: bold;
  color: #37474F;
  font-family:'Times New Roman', Times, serif;
  text-align: center;
  font-style: italic;
}
.my-col-style {
  padding-left: 40px !important;
}
@media (max-width: 767px) {
 .my-col-style {
  padding-left: 24px !important;
}
}
.organization-ticker-settings-alert {
  color: #C62828;
  font-size: 18px;
  text-align: center;
  margin-bottom: 50px;
  margin-top: 10px;
}
</style>
