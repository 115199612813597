<template>
  <v-dialog
    v-model="showModal"
    max-width="700px"
  >
    <v-card
      shaped
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-upload-network-outline
        </v-icon> Apply To Boards
      </h2>
      <br>
      <v-form>
        <v-card-text>
          <p
            class="p-style"
          >
            <v-icon color="#37474F">
              mdi-information-outline
            </v-icon>
            Apply will set this setting as default for all your devices.
          </p>
          <v-container>
            <div class="board-div">
              <v-img
                :src="logo"
                width="130"
                height="110"
                class="blink-image"
              />
              <div>
                <div
                  id="update-div"
                  class="updates"
                >
                  <span><b>{{ currentDateTime }}</b></span>
                </div>
                <div
                  id="ticker-main-div"
                  class="ticker-div"
                >
                  <p :class="tickerScrollSettings">
                    <span v-if="isTickerTitleBefore"><b class="ticker-title-before">Title:</b><b class="ticker-comment-after">This is a sample scrolling text</b></span>
                    <span v-else><b class="ticker-comment-before">This is a sample scrolling text</b><b class="ticker-title-after">-Title</b></span>
                  </p>
                </div>
              </div>
            </div>
            <div class="stand-div" />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mt-2"
            color="red darken-3"
            @click="showModal = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            class="mt-2"
            color="#FF3700"
            :disabled="!isBoardAvailable"
            @click="applyToBoards()"
          >
            Apply
          </v-btn>
        </v-card-actions>
      </v-form>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </v-dialog>
</template>
<script>
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import global from 'src/mixins/global';
import { cdnUrl } from 'src/app-globals';
import Constants from 'src/constants';
import moment from 'moment';

  export default {
    name: 'ContentsUpload',
    components: {
      'centre-spinner': spinner,
    },
    mixins: [global],
    props: {
      tickerSettingsDetails: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      loading: false,
      showModal: true,
      tickerDirection: '',
      tickerSpeed: '',
      logo: `${cdnUrl}/website/DBS-Logo.png`,
      currentDateTime: null,
      interval: null,

    }),
    computed: {
      organizationDigiBoards () {
        return this.$store.getters['digiboards/getOrganizationDigiBoardTimeSlots'];
      },
      isBoardAvailable () {
        if (this.organizationDigiBoards.length > 0) {
          return true;
        }
        return false;
      },
      tickerScrollSettings () {
        if (this.tickerSettingsDetails.direction === 'left') {
          return 'ticker-content-to-left';
        }
        return 'ticker-content-to-right';
      },
      isTickerTitleBefore () {
        if (this.tickerSettingsDetails.title === 'before') {
          return true;
        }
        return false;
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    created () {
      this.currentDateTime = moment().format('MMM DD YYYY, ddd');
      setInterval(() => {
        clearInterval(this.interval);
        this.liveDateTime();
      }, 6000);
    },
    async mounted () {
      await this.applyStyling();
      this.permissionCheck('view-organization-purchase-list') && await this.fetchOrganizationDigiBoardTimeSlots();
    },
    methods: {
      applyStyling () {
        document.getElementById('ticker-main-div').style.color = this.tickerSettingsDetails.text_color;
        document.getElementById('ticker-main-div').style.background = this.tickerSettingsDetails.bg_color;
        document.documentElement.style.setProperty(
          '--ticker-speed',
          `${this.tickerSettingsDetails.speed}s`,
        );
        if (this.tickerSettingsDetails.position === 'top') {
          document.getElementById('ticker-main-div').style.top = 0;
          document.getElementById('update-div').style.top = 0;
        } else {
          document.getElementById('ticker-main-div').style.bottom = 0;
          document.getElementById('update-div').style.bottom = 0;
        }
      },
      async fetchOrganizationDigiBoardTimeSlots () {
        this.loading = true;
        await this.$store.dispatch('digiboards/fetchOrganizationDigiBoardTimeSlots');
        this.loading = false;
      },
      async applyToBoards () {
        this.loading = true;
        const orgDigiBoardIds = [];
        for (const item of this.organizationDigiBoards) {
          orgDigiBoardIds.push(item.digi_board.external_id);
        }
        this.$store.dispatch('publish/addSelectedBoardsForPublish', orgDigiBoardIds);
        await this.$store.dispatch('widgets/applyToBoards', {
          settings_id: this.tickerSettingsDetails.id,
          digiBoardsArray: {
            digi_boards: orgDigiBoardIds,
          },
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Ticker setting applied to all devices.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$store.dispatch('widgets/fetchOrganizationTickerSetting');
          this.loading = false;
          this.showModal = false;
        }).catch(() => {
          this.loading = false;
        });
      },
      liveDateTime () {
        const todaysDate = moment().format('MMM DD YYYY, ddd');
        if (this.currentDateTime === todaysDate) {
          this.interval = setInterval(() =>
            this.getCurrentTime(),
          );
        } else {
          this.currentDateTime = todaysDate;
        }
      },
      getCurrentTime () {
        this.currentDateTime = moment().format('LTS');
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.board-div {
  height: 250px;
  width: 450px;
  overflow: hidden;
  position: relative;
  background-color: black;
  margin: auto;
}
@media (max-width: 767px) {
  .board-div {
    width: 100%;
    height: 200px;
  }
}
.stand-div {
  height: 60px;
  width: 10px;
  overflow: hidden;
  position: relative;
  background-color: black;
  margin: auto;
}
.blink-image {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: blink 1s step-start infinite;
}
@keyframes blink {
  50% {
    opacity: 0;
  }
}
.p-style {
  font-size: 18px;
  font-weight: bold;
  color: #37474F;
  font-family:'Times New Roman', Times, serif;
  text-align: center;
  background-color: #CFD8DC;
  font-style: italic;
}
.updates {
  font-size: 13px;
  font-weight: bold;
  padding: 6px;
  background: white;
  color: black;
  font-family: 'Times New Roman', Times, serif;
  position: absolute;
  z-index: 999;
}
@media (max-width: 767px) {
 .updates {
  font-size: 10px;
 }
}
.ticker-div {
  position: absolute;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: visible;
  padding-top: 4px;
  padding-bottom: 4px;
  z-index: 998;
}
.ticker-content-to-left {
  margin: 0;
  display: inline-block;
  overflow: hidden;
  font-size: 15px;
  padding-left: 100%;
  animation: tickerTape var(--ticker-speed) linear infinite;
}
@keyframes tickerTape {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
.ticker-content-to-right {
  margin: 0;
  display: inline-block;
  overflow: hidden;
  font-size: 15px;
  padding-left: 100%;
  animation: tickerTapes var(--ticker-speed) linear infinite;
}
@keyframes tickerTapes {
  0% {
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.ticker-title-before {
  margin-right: 5px;
  text-decoration: underline;
}
.ticker-comment-after {
  margin-right: 30px;
}
.ticker-title-after {
  margin-left: 5px;
  text-decoration: underline;
}
.ticker-comment-before {
  margin-left: 30px;
}
</style>
