<template>
  <v-dialog
    v-model="showModal"
    max-width="800"
    scrollable
  >
    <div>
      <overlay-alert
        :show-progress="showProgress"
        :process-alert="processAlert"
      />
      <v-card
        shaped
        color="#ECEFF1"
      >
        <br>
        <h2 class="title-style">
          <v-icon class="icon-style">
            mdi-television
          </v-icon> Edit Selected Device
        </h2>
        <br>
        <v-alert
          v-if="!permissionCheck('read-board-list') && !permissionCheck('read-group')"
          text
          prominent
          type="warning"
          color="red"
          border="left"
          class="authorization-alert"
        >
          You are not authorized to perform this action. Please contact your administrator.
        </v-alert>
        <base-tree-view-without-actions
          v-if="permissionCheck('read-board-list') || permissionCheck('read-group')"
          class="my-tree-view-style"
          :value="treeData"
        >
          <span slot-scope="{node, path, tree}">
            <input
              id="myCheck"
              type="checkbox"
              :checked="node.$checked"
              @change="onCheckBoxChange(node, tree, path)"
            >
            {{ node.text }}
          </span>
        </base-tree-view-without-actions>
        <v-card-actions>
          <v-btn
            v-if="!permissionCheck('read-board-list') && !permissionCheck('read-group')"
            color="red darken-3"
            @click="back()"
          >
            Back
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="permissionCheck('read-board-list') || permissionCheck('read-group')"
            class="mt-2"
            color="#FF3700"
            @click="updateTicker()"
          >
            Update
          </v-btn>
        </v-card-actions>
        <centre-spinner
          :loading="loading"
        />
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
  import Constants from 'src/constants';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import { walkTreeData } from 'he-tree-vue';
  import { getDefaultStructure, addMissingBoards } from 'src/helpers/digi-board-structure-helper';
  import global from 'src/mixins/global';
  import moment from 'moment';
  import OverlayAlert from 'src/views/dashboard/component/OverlayAlert';

  export default {
    name: 'SelectDigiBoards',
    components: {
      'centre-spinner': spinner,
      'overlay-alert': OverlayAlert,
    },
    mixins: [global],
     props: {
      editvalues: {
        type: Object,
        required: true,
      },
       tickerValues: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        selectedDigiBoards: [],
        treeData: [],
        loading: false,
        showModal: true,
        schedules: [],
        showProgress: false,
        processAlert: '',
      };
    },
    computed: {
      savedStructure () {
        return this.$store.getters['grouping/getSavedStructure'];
      },
      organizationDigiBoards () {
        return this.$store.getters['digiboards/getOrganizationDigiBoardTimeSlots'];
      },
      advancedScheduleData () {
        return this.$store.getters['schedule/getScheduleData'];
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    async mounted () {
      this.loading = true;
      this.permissionCheck('read-group') && await this.fetchDigiBoardSavedStructure();
      this.permissionCheck('view-organization-purchase-list') && await this.fetchOrganizationDigiBoardTimeSlots();
      this.showBoardTreeList();
      this.updateExisting();
      this.loading = false;
    },
    methods: {
      async fetchDigiBoardSavedStructure () {
        await this.$store.dispatch('grouping/fetchSavedStructure');
      },
      async fetchOrganizationDigiBoardTimeSlots () {
        this.loading = true;
        await this.$store.dispatch('digiboards/fetchOrganizationDigiBoardTimeSlots');
        this.loading = false;
      },
      showBoardTreeList () {
        if (Object.keys(this.savedStructure).length <= 0) {
          const digiBoards = this.organizationDigiBoards;
          this.treeData = getDefaultStructure(digiBoards);
          return;
        }
        const treeData = this.savedStructure.group;
        const digiBoards = this.organizationDigiBoards;
        this.treeData = addMissingBoards(treeData, digiBoards);
      },
      updateExisting () {
        const existingBoards = this.tickerValues.boards;
        walkTreeData(this.treeData, (node) => {
          if (existingBoards.includes(node.id)) {
            node.$checked = true;
          }
        });
      },
      onCheckBoxChange (node, tree, path) {
        tree.toggleCheck(node, path);
        const selectedNodes = [];
        tree.walkTreeData((node) => {
          node.$checked && selectedNodes.push(node);
        });
        this.selectedDigiBoards = selectedNodes;
      },
      async updateDigiBoardLink () {
        if (this.permissionCheck('sync-ticker') === false) {
          this.showProgress = false;
          this.processAlert = '';
          this.showModal = false;
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.processAlert = 'Linking...';
        const finalResult = [];
        walkTreeData(this.treeData, (node) => {
          if (node.$checked && node.id !== undefined) {
            finalResult.push(node.id);
          }
        });
        this.$store.dispatch('publish/addSelectedBoardsForPublish', finalResult);
        await this.$store.dispatch('widgets/updateTickerToBoardLink', {
          ticker_id: this.tickerValues.id,
          digiBoards: { digi_boards: finalResult },
        }).then(response => {
          this.processAlert = '';
          this.showProgress = false;
          this.$store.dispatch('alert/onAlert', {
            message: 'Ticker Updated successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$emit('update-success');
          this.showModal = false;
        }).catch(() => {
          this.processAlert = '';
          this.showProgress = false;
          this.showModal = false;
        });
      },
      back () {
        this.showModal = false;
      },
      convertToGmt (date) {
        return moment.utc(date).format();
      },
      async updateTicker () {
        this.showProgress = true;
        this.processAlert = 'Updating...';
        this.schedules = [];
        for (const advancedSchedule of this.advancedScheduleData) {
          this.schedules.push(advancedSchedule.id);
        }
        await this.$store.dispatch('widgets/updateTicker', {
          TickerId: this.tickerValues.id,
          tickerUpdateData: {
            title: this.editvalues.tickerTitle,
            comment: this.editvalues.comment,
            start: this.convertToGmt(this.editvalues.start),
            end: this.editvalues.end !== null ? this.convertToGmt(this.editvalues.end) : null,
            schedules: this.schedules,
          },
        }).then(response => {
          this.updateDigiBoardLink();
        }).catch(() => {
          this.showProgress = false;
          this.processAlert = '';
          this.showModal = false;
        });
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-tree-view-style {
  margin-left: 30px;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
</style>
